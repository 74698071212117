export enum Currency {
  USD = 'USD',
  RUB = 'RUB',
  EUR = 'EUR',
}

export enum OrderStatus {
  Working = 0,
  Hold = 1,
  Processing = 2,
  Ready = 4,
  Success = 8,
  Rejected = 3,
}

export enum PlayerFormat {
  Slider = 1,
  // Interstitial = 2,
  InPage = 3,
  // InStream = 4,
  Floating = 5,
}
