import { BaseApi } from '@/shared/api/createApi';
import { redirectToMain } from '@/shared/lib';

import type {
  ILoginError,
  ILoginErrorResponse,
  ILoginRequest,
  ILoginSuccessResponse,
  ISocialLoginRequest,
  IUserData,
  RegistrationArgs,
  UpdateArgs,
} from '../model/types';
import { ILoginAsUserRequest } from '../model/types';

export const authApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (build) => ({
      login: build.mutation<ILoginSuccessResponse, ILoginRequest>({
        query: (data: ILoginRequest) => ({
          url: '/user/login',
          method: 'POST',
          body: data,
        }),
        transformResponse: (response: ILoginSuccessResponse) => {
          localStorage.setItem('token', response.token);
          return response;
        },
        transformErrorResponse: (
          response: ILoginErrorResponse
        ): ILoginError['message'] => response.data.message,
      }),
      socialLogin: build.mutation<ILoginSuccessResponse, ISocialLoginRequest>({
        query: ({ socialite, code }: ISocialLoginRequest) => ({
          url: `/v2/user/${socialite}/login`,
          method: 'POST',
          body: { socialite, code },
        }),
        transformResponse: (response: ILoginSuccessResponse) => {
          localStorage.setItem('token', response.token);
          return response;
        },
        transformErrorResponse: (
          response: ILoginErrorResponse
        ): ILoginError['message'] => response.data.message,
      }),
      loginAsUser: build.mutation<ILoginSuccessResponse, ILoginAsUserRequest>({
        query: (data: ILoginAsUserRequest) => ({
          url: '/api/login-as-user',
          method: 'POST',
          body: data,
        }),
        transformResponse: (response: ILoginSuccessResponse) => {
          localStorage.setItem('token', response.token);
          return response;
        },
        transformErrorResponse: (
          response: ILoginErrorResponse
        ): ILoginError['message'] => response.data.message,
      }),
      checkUser: build.query<IUserData, void | unknown>({
        query: () => ({
          url: '/user/check',
        }),
        providesTags: ['User'],
        transformResponse: (response: IUserData) => {
          // eslint-disable-next-line @typescript-eslint/dot-notation
          response.user['is_verified'] = response.user?.info
            ? response.user.info.email_verified_at !== null
            : true;
          return response;
        },
        transformErrorResponse: (
          response: ILoginErrorResponse
        ): ILoginError => {
          if (response.status === 401) {
            localStorage.removeItem('token');
            return { message: 'Unauthorized' };
          }
          return response.data;
        },
      }),
      logout: build.mutation<void, void>({
        query: () => ({
          url: '/user/logout',
          method: 'POST',
        }),
        transformResponse: () => {
          localStorage.removeItem('token');
          redirectToMain();
        },
      }),
      create: build.mutation<ILoginSuccessResponse, RegistrationArgs>({
        query: (data: RegistrationArgs) => ({
          url: '/v2/user/create',
          method: 'POST',
          body: data,
        }),
        transformResponse: (response: ILoginSuccessResponse) => {
          localStorage.setItem('token', response.token);
          return response;
        },
        transformErrorResponse: (
          response: ILoginErrorResponse
        ): ILoginError['message'] => response.data.message,
      }),
      update: build.mutation<IUserData['user'], UpdateArgs>({
        query: (patch) => ({
          url: `/user/update`,
          method: 'PUT',
          body: patch,
        }),
        invalidatesTags: ['User'],
        transformResponse: (response: IUserData) => response.user,
        transformErrorResponse: (
          response: ILoginErrorResponse
        ): ILoginError['message'] => response.data.message,
      }),
    }),
  });
