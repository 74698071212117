/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { authApiAdvertiser, authApiPublisher } from '@/entities/auth';
import { currencyDetails } from '@/shared/lib/constants/currency';
import { Currency } from '@/shared/types';

import type { SettingsState } from './types';

export const settingsSliceName = 'settings';

const initialState: SettingsState = {
  currency: Currency.USD,
};

const settingsSlice = createSlice({
  name: settingsSliceName,
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(authApiPublisher.endpoints.checkUser.matchFulfilled),
      (state, action) => {
        const { currency } = action.payload.user;
        state.currency = currency;
      }
    );
    builder.addMatcher(
      isAnyOf(authApiAdvertiser.endpoints.checkUser.matchFulfilled),
      (state, action) => {
        const { currency } = action.payload.user;
        state.currency = currency;
      }
    );
  },
  selectors: {
    selectLocale: (state) => currencyDetails[state.currency].localization,
    selectCurrencySign: (state) => currencyDetails[state.currency].sign,
  },
});

export const { selectLocale, selectCurrencySign } = settingsSlice.selectors;

export default settingsSlice.reducer;
