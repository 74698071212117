import endOfYear from 'date-fns/endOfYear';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import startOfYear from 'date-fns/startOfYear';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import subYears from 'date-fns/subYears';

const today = new Date();

const past6days = subDays(new Date(), 6);

const yesterday = subDays(new Date(), 1);

const past29days = subDays(new Date(), 29);

const prevMonth = subMonths(new Date(), 1);

const prevYear = subYears(new Date(), 1);

const periods = [
  { children: 'Today', value: [today, today] },
  { children: 'Yesterday', value: [yesterday, yesterday] },
  { children: 'Last 7 days', value: [past6days, today] },
  { children: 'Last 30 days', value: [past29days, today] },
  { children: 'This Month', value: [startOfMonth(today), today] },
  {
    children: 'Last Month',
    value: [startOfMonth(prevMonth), lastDayOfMonth(prevMonth)],
  },
  { children: 'This Year', value: [startOfYear(today), today] },
  {
    children: 'Last Year',
    value: [startOfYear(prevYear), endOfYear(prevYear)],
  },
];

const smallPeriods = [
  { children: 'Today', value: [today, today] },
  { children: 'Yesterday', value: [yesterday, yesterday] },
  { children: 'Last 7 days', value: [past6days, today] },
  { children: 'Last 30 days', value: [past29days, today] },
  { children: 'This Month', value: [startOfMonth(today), today] },
  {
    children: 'Last Month',
    value: [startOfMonth(prevMonth), lastDayOfMonth(prevMonth)],
  },
  { children: 'Last 3 months', value: [subMonths(new Date(), 3), today] },
];

export { smallPeriods };

export default periods;
